import React from 'react';

import '../../styles.css';
import SEOComponent from '../components/fields/seoField';
import CommonPageRenderer from '../components/pageRendererHoc/CommonPageRenderer';

const Faq = () => {
	return (
		<div className='static-page faq'>
			<CommonPageRenderer>
				<SEOComponent
					title='FAQ - Snakes & Ladders Plus'
					description='FAQs LOGIN RELATED Can I login with multiple accounts on my phone? No, you can only login with one account on your device. I’m unable to login even after providing my email address. Write & share your concern with us on support@snakesandladdersplus.com GAME RELATED What are the rules of the game and how is the [...]'
				/>
				<div className='page-content mainContainer'>
					<h2 class='page-heading align-center'>FAQs</h2>
					<h3 className='page-subheading align-center'>LOGIN RELATED</h3>
					<p className='question align-left'>Can I login with multiple accounts on my phone?</p>
					<p className='answer'>No, you can only login with one account on your device.</p>
					<p className='question align-left'>I’m unable to login even after providing my email address.</p>
					<p className='answer'>
						Write & share your concern with us on{' '}
						<a class='sub-page-link' href='mailto:support@snakesandladdersplus.com'>
							support@snakesandladdersplus.com
						</a>
					</p>
					<h3 className='page-subheading align-center'>GAME RELATED</h3>
					<p className='question align-left'>What are the rules of the game and how is the winner decided?</p>
					<ol class='sub-page-ul'>
						<li>All players will get 3 tokens in the open position.</li>
						<li>You get 1 point for each box moved forward. Get extra 100 points for each token that reaches Home.</li>
						<li>The player with the highest score at the end of the game will be the winner.</li>
						<li>
							Get an extra move when
							<ol type='a'>
								<li>A token reaches Home.</li>
								<li>By cutting the opponent’s token.</li>
							</ol>
						</li>
						<li>All players get equal number of 1s, 2s,3s,4s,5s,6s.</li>
						<li>
							Climb ladders to move up faster. Landing on the snake’s head will move you to the tail of the snake and
							you will lose points.
						</li>
						<li>
							If your token is cut by another player then you will lose points and the token will go back to starting
							position. Remember, two tokens of a player in same box cannot be cut.
						</li>
						<li>The game has a limited number of moves, so plan each move carefully.</li>
						<li>
							Each move must be played within 20 seconds, failing which the move is Skipped. After the 3rd skip you will
							be out of the game and your opponent will win
						</li>
						<li>
							In order to increase the probability of winning a game, a player must remember what number has been played
							by the player and the other player(s) – in order to build the player’s strategy for making a quick and
							informed decision on deciding which token to move – depending on the placement of the token of the player
							and other player(s) in respective boxes – and the presence of the ladders and the snakes.
						</li>
					</ol>
					<p className='question align-left'>When do I get an extra move in the game?</p>
					<p className='answer'>Upon your token reaching home and upon cutting opponent’s token.</p>
					<p className='question align-left'>Will I get an extra move after a 6 number move?</p>
					<p className='answer'>No. Moving on a 6 does not give an extra move.</p>
					<p className='question align-left'>How is the extra move given?</p>
					<p className='answer'>The extra move is always random generated. It can be any random number from 1 to 6.</p>
					<p className='question align-left'>What’s +100 that I see when a token reaches home?</p>
					<p className='answer'>When a token reaches home, you get an extra +100 points.</p>
					<p className='question align-left'>Who am I playing against in the Online section?</p>
					<p className='answer'>You are randomly matched against other players on </p>
					<p className='question align-left'>How do I register for a match?</p>
					<p className='answer'>
						Simply tap the amount required to play the match. Click Register on the pop-up and you will be registered for
						that match.
					</p>
					<p className='question align-left'>Can I unjoin a match I joined before it starts?</p>
					<p className='answer'>No, you can’t unjoin once you have joined the match.</p>
					<p className='question align-left'>I want to turn on the sound/music.</p>
					<p className='answer'>Go to Settings > Sound/Music > ON</p>
					<p className='question align-left'>
						I am from Assam / Odisha / Sikkim / Nagaland / Telangana / Meghalaya / Andhra Pradesh /Arunachal Pradesh / and
						am unable to play paid games. Why am I only allowed to play free games?
					</p>
					<p className='answer'>
						We would like to inform you that players from Assam, Odisha, Sikkim, Andhra Pradesh, Nagaland, Arunachal
						Pradesh, Meghalaya and Telangana are not allowed to play real money games on Snakes and Ladders Plus. This is
						due to policy reasons, and we firmly abide by the same.
					</p>
					<p className='question align-left'>Can I play the regular snakes and ladder format in the app?</p>
					<p className='answer'>No, you can only play the format available</p>
					<p className='question align-left'>I left a match by mistake, can I rejoin?</p>
					<p className='answer'>No, you can’t rejoin a match you quit/left previously.</p>
					<p className='question align-left'>I had poor internet connection during the match.</p>
					<p className='answer'>
						An unstable internet connection can lead to a disqualification. A refund is not issued on disqualification.
					</p>
					<p className='question align-left'>What if there is a tie in the game?</p>
					<p className='answer'>The prize money is split in case of a tie.</p>
					<p className='question align-left'>Can I join a game after it has started?</p>
					<p className='answer'>No, you cannot join a game in between.</p>
					<p className='question align-left'>Where can I see my available skips during a game?</p>
					<p className='answer'>
						You can click on ‘i’ near your avatar to see your available skips. You can tap the ‘i’ near the avatar of
						other players to see their available skips. Please note, you don’t get any extra turn in place of the turns
						you skip.
					</p>
					<p className='question align-left'>How many times can I skip my turn?</p>
					<p className='answer'>You can skip your turn twice only. You are disqualified on the 3rd skip.</p>
					<p className='question align-left'>My clicks don’t register/lag.</p>
					<p className='answer'>Maintain a good internet connection.</p>
					<p className='question align-left'>Can I stop the vibration during my turn?</p>
					<p className='answer'>
						Vibrations are used to remind you that it’s your turn. However, vibrations can be turned off through the
						toggle during gameplay by clicking on the back arrow and switching it off.
					</p>
					<p className='question align-left'>I think I didn’t get an equal number of moves.</p>
					<p className='answer'>We ensure that every user gets the same number of moves.</p>
					<p className='question align-left'>Does my token move automatically?</p>
					<p className='answer'>No, you need to tap the token you want to move.</p>
					<p className='question align-left'>I was unable to find specific matches.</p>
					<p className='answer'>
						Either you have selected a filter that hides the matches you are looking for, or some matches may not be
						available at that time.
					</p>

					<h3 className='page-subheading align-center'>Profile Related</h3>
					<p className='question align-left'>How do I edit my Display Photo?</p>
					<p className='answer'>
						Tap the placeholder icon next to your name on the top left corner. Click on Edit Avatar. Take a picture or
						select a picture from your gallery.
					</p>
					<p className='question align-left'>Can I use any image?</p>
					<p className='answer'>
						Tap on your wallet &gt; We recommend you use your own picture as a profile avatar. Your image should not
						contain nudity or otherwise offensive or illegal content.
					</p>
					<p className='question align-left'>Can I change my email id or phone number associated with my account?</p>
					<p className='answer'>No, it’s not possible to change these details.</p>
					<p className='question align-left'>Can I play from a territory outside India?</p>
					<p className='answer'>
						Only residents of India (outside of Assam, Odisha, Telangana, Andhra Pradesh, Sikkim, Meghalaya, Arunachal
						Pradesh and Nagaland) can play the game.
					</p>
					<p className='question align-left'>My account was blocked, what can I do?</p>
					<p className='answer'>
						In case your account was blocked, you can raise a support request on{' '}
						<a class='sub-page-link' href='mailto:support@snakesandladdersplus.com'>
							support@snakesandladdersplus.com
						</a>
					</p>
					<p className='question align-left'>Can I play Snakes and Ladders Plus if I am under 18?</p>
					<p className='answer'>No, you need to be 18 or older to play Snakes and Ladders Plus.</p>
				</div>
			</CommonPageRenderer>
		</div>
	);
};

export default Faq;
